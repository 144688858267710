import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';


const WishlistNavButton = ({ parentClick }) => {
  const count = useSelector(state => state.wishlist.count) || 0;
  const wishlistLoaded = useSelector(state => state.wishlist.wishlistLoaded);
  const authenticated = useSelector(state => state.auth.authenticated);

  if (authenticated && !wishlistLoaded) {
    return (
      <Link to="/wishlist" className="nav-wishlist-btn" aria-label="Wishlist" onClick={parentClick}>
        <FontAwesomeIcon icon={faHeart} />
      </Link>
    );
  }

  return (
    <Link to="/wishlist" className="nav-wishlist-btn" aria-label="Wishlist" onClick={parentClick}>
      <FontAwesomeIcon icon={faHeart} />
      <span className={`nav-badge nav-badge-${count}`}>
        {count}
      </span>
    </Link>
  );
};


WishlistNavButton.propTypes = {
  parentClick: PropTypes.func.isRequired,
};

WishlistNavButton.defaultProps = {
  parentClick: () => {},
};

export default WishlistNavButton;
